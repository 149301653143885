.repairRoot {
  position: relative;
  height: 100%;
}
.legends {
  position: absolute;
  width: 212px;
  bottom: 40px;
  right: 780px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #FFF;
  padding: 6px;
}
.legends:empty {
  display: none;
}
.legends .legend {
  width: 100px;
}
.legends .legend code {
  margin-right: 4px;
}
