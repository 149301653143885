
@import "../../variables.less";
.repairRoot {
	position: relative;
	height: 100%;
}

.legends {
	position: absolute;
	width: 200px + 2 * @middleSpace;
	bottom: 40px;
	right: 780px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	color: #FFF;
	padding: @middleSpace;

	&:empty {
		display: none;
	}

	.legend {
		width: 100px;

		code {
			margin-right: @smallSpace;
		}
	}
}
